<template lang="">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body h-100">
        <div class="mb-3">
          <h3>
            จัดการแอดมิน
          </h3>
          <div class="separator" />
        </div>
        <list-admin />
      </div>

    </div>

  </div>
</template>
<script>
import ListAdmin from './listAdmin.vue'

export default {
  components: {
    ListAdmin,
  },
  data() {
    return {

    }
  },
}
</script>
<style lang="">

</style>
